import { Button, Card, Collection, Heading, View } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import { downloadData, list } from 'aws-amplify/storage';

export const ListItems = () => {
    
    const [data, setData] = useState([])
    
    useEffect(() => {
        list({
            options: {
                listAll: true,
                /* pageSize: PAGE_SIZE,
                nextToken: nextToken */
            }
        })
        .then(response => {
            let results = response.items.flatMap(            
                function (obj) {
                    if (obj.size === 0) {
                        return null
                    }
                    return obj
                }
            ).filter(n => n)
            setData(results)            
        })
        .catch(err => {
            console.log(err)
        })
    }, [])
    
    return <Collection
        items={data}
        type="list"
        direction="row"
        gap="20px"
        wrap="nowrap"
        padding="medium">
        {(item, index) => (
            <Card    
            key={index}
            borderRadius="medium"
            maxWidth="20rem"
            variation="outlined">            
            <View padding="xs">        
                <Heading padding="medium">{item.key}</Heading>
                {/* <Flex padding="medium">            
                    {item.badges.map((badge) => (                
                        <Badge
                        key={badge}
                        backgroundColor={
                            badge === 'Waterfront' ? 'blue.40' 
                                : badge === 'Mountain' ? 'green.40'
                                    : 'yellow.40'
                        }>                        
                            {badge}
                        </Badge>            
                    ))}
                </Flex> */}
                <Button variation="primary" isFullWidth onClick={() => download(item.key)}>
                    Download              
                </Button>    
            </View>      
        </Card>
        )}
    </Collection>    
};

async function download(key) {
    try {
        const downloadResult = await downloadData({ key: key }).result;
        const blob = await downloadResult.body.blob();        
        // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
        const blobUrl = URL.createObjectURL(blob);
        // Create a link element
        const link = document.createElement('a');
        // Set link's href to point to the Blob URL
        link.href = blobUrl;
        link.download = key;
        // Append link to the body
        document.body.appendChild(link);
        // Dispatch click event on the link
        // This is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        })
        );

        // Remove link from body
            document.body.removeChild(link);        
      } catch (error) {
        console.log('Error : ', error);
    }
    
    
}