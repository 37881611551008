import './App.css';
import { ListItems } from './listItems';
import { withAuthenticator, Text } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig)

function App({ signOut, user }) {
  return (
    <div className="App">
      <Text>Hello {user.username}</Text>
      <ListItems />
      <button onClick={signOut}>Sign out</button>
    </div>
  );
}

export default withAuthenticator(App);
